import { useMemo } from 'react';
import useStore from '../../../store/useStore';
import useCities from '../cities/api';
import { getCountries } from '../countries/api';
import { getDistricts } from '../districts/api';
import useGolfCourses from '../golfCourses/api';
import getGolfClubInfo from '../golfCourses/getGolfClubInfo';
import isGolfCourseNearCity, { CityType } from './isGolfCourseNearCity';

const useSearchData = () => {
  const withAccommodation = useStore(state => state.withAccommodation);
  const { data: allGolfCourses, isLoading: isLoadingGolfCourses } = useGolfCourses();
  const { data: allCities, isLoading: isLoadingCities } = useCities();

  return useMemo(() => {
    const golfCourses = withAccommodation
      ? allGolfCourses?.filter(golfCourse => !!getGolfClubInfo(golfCourse)?.accommodationId)
      : allGolfCourses;
    const countries = getCountries(golfCourses);
    const districts = getDistricts(golfCourses);
    const cities = withAccommodation
      ? allCities?.filter((city: CityType) =>
          golfCourses?.some(golfCourse => isGolfCourseNearCity(golfCourse, city))
        )
      : allCities;

    return {
      allGolfCourses,
      golfCourses,
      countries,
      districts,
      cities,
      isLoading: isLoadingGolfCourses || isLoadingCities
    };
  }, [allGolfCourses, withAccommodation, allCities, isLoadingGolfCourses, isLoadingCities]);
};

export default useSearchData;
