import React, { FC, Suspense } from 'react';

import StickyCart from './components/Cart/StickyCart';
import GolfamoreUser from './components/GolfamoreUser';
import InfoModal from './components/InfoModal';
import InitialData from './components/InitialData';
import NeedHelpToast from './components/NeedHelpToast/NeedHelpToast';
import VouchersBanner from './components/VouchersBanner/VouchersBanner';
import { voucherBannerDefaultAmount } from './components/VouchersBanner/defaultAmount';
import Helmet from './components/common/Helmet';
import ScrollToTop from './components/common/ScrollToTop';
import AppRouter from './components/router/AppRouter';
import Navbar from './components/router/Navbar';
import FooterContainer from './containers/FooterContainer';
import AuthModal from './containers/auth/AuthModal';
import { useAffiliateInit } from './services/apis/affiliate/api';
import useStore from './store/useStore';

const App: FC = () => {
  const { authModalOpen, appInfo } = useStore(state => state);
  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);

  useAffiliateInit();

  return (
    <>
      <Helmet />
      <Suspense fallback={<p />}>
        <InitialData />
        {isGolfhaftetRequest ? (
          <GolfamoreUser />
        ) : (
          <VouchersBanner {...voucherBannerDefaultAmount} />
        )}
        <Navbar />
        <ScrollToTop />
        <AppRouter />

        <FooterContainer />
        {authModalOpen && <AuthModal />}
        {appInfo && <InfoModal />}
        <div className="fixed-bottom d-flex flex-column gap-3" style={{ pointerEvents: 'none' }}>
          <NeedHelpToast />
          <StickyCart />
        </div>
      </Suspense>
    </>
  );
};

export default App;
