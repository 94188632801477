import { Slice } from './types';

export interface AppSlice {
  authModalOpen: boolean;
  authModalSource?: 'giftCardForm' | 'gmBookingFlow';
  authModalEmail?: string | null;
  authModalGmCardNr?: string | null;
  openAuthModal: (
    visible: boolean,
    source?: 'giftCardForm' | 'gmBookingFlow',
    email?: string | null,
    gmCardNr?: string | null
  ) => void;

  language: string;
  setLanguage: (language: string) => void;

  appInfo: Record<'title' | 'text', string> | null;
  setAppInfo: (title: string, text: string) => void;
  clearAppInfo: () => void;

  vouchersBannerClosed: boolean;
  setVouchersBannerClosed: () => void;

  needHelpToastClosedDate: string;
  setNeedHelpToastClosed: (date: string) => void;

  cartHintCloseDate: Date | null;
  setCartHintCloseDate: (date: Date) => void;
}

const createAppSlice: Slice<AppSlice> = set => ({
  authModalOpen: false,
  authModalSource: undefined,
  openAuthModal: (visible, source, email, gmCardNr) =>
    set(() => ({
      authModalOpen: visible,
      authModalSource: visible ? source : undefined,
      authModalEmail: visible ? email : undefined,
      authModalGmCardNr: visible ? gmCardNr : undefined,
    })),

  language: '',
  setLanguage: language =>
    set(() => ({
      language,
    })),

  appInfo: null,
  setAppInfo: (title, text) =>
    set(() => ({
      appInfo: { title, text },
    })),
  clearAppInfo: () =>
    set(() => ({
      appInfo: null,
    })),

  vouchersBannerClosed: false,
  setVouchersBannerClosed: () =>
    set(() => ({
      vouchersBannerClosed: true,
    })),

  needHelpToastClosedDate: '',
  setNeedHelpToastClosed: date =>
    set(() => ({
      needHelpToastClosedDate: date,
    })),

  cartHintCloseDate: null,
  setCartHintCloseDate: date =>
    set(() => ({
      cartHintCloseDate: date,
    })),
});

export default createAppSlice;
