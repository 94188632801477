import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import IconLocationOn from 'material-react-icons/LocationOn';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import getGolfCourseUrl from '../../services/apis/golfCourses/getGolfCourseUrl';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import Button from '../Button/Button';
import LazyImg from '../LazyImg/LazyImg';
import styles from './GolfCourseCard.module.scss';

interface GolfCourseCardProps {
  golfCourse: MappedGolfCourseType;
  isAccommodation?: boolean;
  responsive?: boolean;
}

const GolfCourseCard: FC<GolfCourseCardProps> = props => {
  const { golfCourse, isAccommodation, responsive } = props;
  const info = getGolfClubInfo(golfCourse);
  const { translate, activeLanguage } = useTranslate();

  return (
    <Link
      to={`/${activeLanguage.code}/booking/${getGolfCourseUrl(golfCourse)}${
        isAccommodation ? '?acc=1' : ''
      }`}
      className={classNames(styles.container, responsive && styles.responsive)}
    >
      <div className={styles.imageWrapper}>
        <LazyImg className={styles.image} src={info?.image} alt={golfCourse.name} />
      </div>

      <div className={styles.body}>
        <h3 className={styles.title}>{golfCourse.golfClub.name}</h3>
        <h4 className={styles.subtitle}>{golfCourse.name}</h4>
        <div className={styles.location}>
          <IconLocationOn size={20} />
          {golfCourse.country.name}
        </div>
      </div>

      <div className={styles.footer}>
        <Button primary>{translate('default.book')}</Button>
      </div>
    </Link>
  );
};

export default GolfCourseCard;
