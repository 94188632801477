import React, { FC } from 'react';

import { offset } from '@floating-ui/react';

import DatePicker, { DatePickerProps } from './DatePicker/DatePicker';

type CalenderPropsType = {
  selectedDate: string;
} & DatePickerProps;

const Calendar: FC<CalenderPropsType> = props => {
  const { selectedDate, ...rest } = props;

  return (
    <div className="d-flex align-items-center form-control">
      <div className="icon-calendar" style={{ fontSize: '18px' }} />
      <DatePicker
        closeOnScroll
        dateFormat="P"
        minDate={new Date()}
        selected={
          selectedDate
            ? // parse as local time to prevent date gaps for different time zones
              new Date(`${selectedDate}T00:00:00`)
            : null
        }
        popperPlacement="bottom"
        {...rest}
        popperModifiers={[
          offset({
            mainAxis: 5,
            crossAxis: 13,
          }),
        ]}
        showPopperArrow={false}
      />
    </div>
  );
};

export default Calendar;
