import React, {
  FC,
  FocusEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { PulseLoader } from 'react-spinners';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { emailRegex, golfIdRegex, passwordRegex } from '../helpers/regex';
import useTranslate from '../hooks/useTranslate';
import { PrivacyPolicyContent } from '../pages/privacyPolicy';
import { TermsUseContent } from '../pages/termsUser';
import { ontee } from '../services';
import useLogin from '../services/apis/auth/login';
import registerUser from '../services/apis/auth/register';
import { RegisterInputType } from '../services/apis/auth/register.types';
import { CountryType } from '../services/apis/countries/types';
import { GolfClubType } from '../services/apis/golfCourses/types';
import useStore from '../store/useStore';
import Helmet from './common/Helmet';
import Checkbox from './form/Checkbox';
import Input from './form/Input';
import Select from './form/Select';

interface ExternalLinkWithModalProps {
  component: FC;
  href: string;
  isGolfhaftetRequest: boolean;
}

const currencyOptions = [
  { id: 2, name: 'EUR' },
  { id: 1, name: 'SEK' },
];

const ExternalLinkWithModal: FC<PropsWithChildren<ExternalLinkWithModalProps>> = props => {
  const { component: Component, href, isGolfhaftetRequest, children } = props;
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    e => {
      if (window.top !== window || isGolfhaftetRequest) {
        e.preventDefault();
        setVisible(true);
      }
    },
    [isGolfhaftetRequest]
  );

  const handleToggle = () => setVisible(v => !v);

  return (
    <>
      {visible && (
        <Modal isOpen centered toggle={handleToggle}>
          <ModalHeader toggle={handleToggle} className="px-3 pt-3" />
          <ModalBody>
            <Component />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleToggle}>
              <Translate id="Register.form.termsCheckbox.close_modal_cta" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <a href={href} target="_blank" rel="noreferrer" onClick={handleClick}>
        {children}
      </a>
    </>
  );
};

interface RegisterFormProps {
  showLogin?: () => void;
}

const RegisterForm: FC<RegisterFormProps> = props => {
  const { showLogin } = props;
  const { translate, activeLanguage } = useTranslate();
  const [error, setError] = useState<boolean | string>(false);
  const [inProgress, setInProgress] = useState(false);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [golfClubs, setGolfClubs] = useState<GolfClubType[]>([]);
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);
  const golfhaftetUser = useStore(state => state.golfhaftetUser);

  const { mutate: login, isLoading } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<RegisterInputType>({
    defaultValues: {
      email: golfhaftetUser?.email ?? '',
      cemail: golfhaftetUser?.email ?? '',
      firstName: golfhaftetUser?.firstName ?? '',
      lastName: golfhaftetUser?.lastName ?? '',
      ghCardNr: golfhaftetUser?.cardNr ?? '',
      terms: false,
    },
  });

  useEffect(() => {
    getCountries();
    getGolfClubs();
  }, []);

  const getCountries = async () => {
    try {
      const countries: CountryType[] = await ontee.countries.getAll();

      setCountries(countries.sort((a, b) => a.name.localeCompare(b.name)));
    } catch {}
  };

  const onCountryChange = (countryId: CountryType['id']) => {
    const country = countries.find(c => c.id === parseInt(`${countryId}`, 10));
    setShowExtraInfo(country?.code === 'SE');
  };

  const getGolfClubs = async () => {
    try {
      const clubs: GolfClubType[] = await ontee.clubs.getByCountryCode('SE');

      setGolfClubs(clubs.sort((a, b) => a.name.localeCompare(b.name)));
    } catch {}
  };

  const formatGolfId = useCallback<FocusEventHandler<HTMLInputElement>>(
    e => {
      const golfId = e.target.value;
      if (golfId?.length > 6 && !golfId.includes('-')) {
        setValue('golfId', `${golfId.substring(0, 6)}-${golfId.substring(6)}`);
      }
    },
    [setValue]
  );

  const onSubmit = useCallback<SubmitHandler<RegisterInputType>>(
    async data => {
      try {
        setInProgress(true);

        const response = await registerUser(data);
        if (response) {
          const { email, password } = data;
          login({ username: email, password });
        } else {
          setError(true);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else if (err instanceof String) {
          setError(String(err));
        }
      } finally {
        setInProgress(false);
      }
    },
    [login]
  );

  const disableButton = inProgress || isLoading;

  return (
    <>
      <Helmet
        titleTranslationKey="Register.Title"
        descriptionTranslationKey="Register.Description"
      />
      <div className="d-flex">
        <Container className="p-0 mt-4">
          <Card>
            <CardHeader className="pt-5 px-3 px-sm-5">
              <h1>
                <Translate id="Register.Title" />
              </h1>
            </CardHeader>
            <CardBody className="px-3 pt-0 px-sm-5">
              <Button color="link mb-3" onClick={showLogin}>
                <Translate id="SignInContainerLogin" />
              </Button>
              {isGolfhaftetRequest && (
                <div className="mt-3 alert alert-warning">
                  <h5>Golfamore</h5>
                  <p>
                    {translate('Register.GolfhäftetCardInfo', {
                      cardNr: golfhaftetUser?.cardNr,
                    })}
                  </p>
                  {golfhaftetUser?.cardNr && <input type="hidden" {...register('ghCardNr')} />}
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      name="email"
                      label={String(translate('default.Email'))}
                      register={register}
                      rules={{ required: true, pattern: emailRegex }}
                      disabled={isLoading}
                      error={errors.email && translate('Register.form.email.error')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      name="cemail"
                      label={String(translate('Register.form.cemail.Label'))}
                      register={register}
                      rules={{ required: true, validate: value => value === getValues('email') }}
                      disabled={isLoading}
                      error={errors.cemail && translate('Register.form.cemail.error')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      name="firstName"
                      label={String(translate('default.FirstName'))}
                      autoComplete="given-name"
                      register={register}
                      rules={{ required: true, minLength: 2 }}
                      disabled={isLoading}
                      error={errors.firstName && translate('Register.form.firstName.error')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      name="lastName"
                      label={String(translate('default.LastName'))}
                      autoComplete="family-name"
                      register={register}
                      rules={{ required: true, minLength: 2 }}
                      disabled={isLoading}
                      error={errors.lastName && translate('Register.form.lastName.error')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <Select
                      name="countryId"
                      label={String(translate('Register.form.nationality.Label'))}
                      placeholder={String(translate('Register.form.nationality.placeholder'))}
                      register={register}
                      rules={{ required: true }}
                      options={countries}
                      disabled={isLoading}
                      onChange={e => onCountryChange(parseInt(e.target.value, 10))}
                      error={errors.countryId && translate('Register.form.nationality.error')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Select
                      name="currencyId"
                      showPlaceholder={false}
                      label={String(translate('Register.form.currency.Label'))}
                      helperText={String(translate('Register.form.currency.helpText'))}
                      register={register}
                      rules={{ required: true }}
                      options={currencyOptions}
                      disabled={isLoading}
                      error={errors.currencyId && translate('Register.form.currency.error')}
                    />
                  </Col>
                </Row>

                {showExtraInfo && (
                  <Row>
                    <Col xs={12} sm={6}>
                      <Input
                        name="golfId"
                        label={String(translate('Register.form.golfId.Label'))}
                        autoComplete="off"
                        onBlur={formatGolfId}
                        register={register}
                        rules={{
                          pattern: golfIdRegex,
                        }}
                        disabled={isLoading}
                        helperText={translate('Register.form.golfId.helpText')}
                        error={errors.golfId && translate('Register.form.golfId.error')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Select
                        name="memberIn"
                        label={String(translate('Register.form.memberIn.Label'))}
                        register={register}
                        options={golfClubs}
                        disabled={isLoading}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      name="password"
                      type="password"
                      label={String(translate('default.Password'))}
                      autoComplete="off"
                      register={register}
                      rules={{
                        required: true,
                        pattern: passwordRegex,
                      }}
                      error={errors.password && translate('Register.form.password.error')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      name="confirmPassword"
                      type="password"
                      label={String(translate('default.ConfirmPassword'))}
                      autoComplete="off"
                      register={register}
                      rules={{
                        required: true,
                        validate: value => value === getValues('password'),
                      }}
                      error={errors.confirmPassword && translate('Register.form.cPassword.error')}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Checkbox
                      name="terms"
                      label={
                        <Translate
                          id="Register.form.termsCheckbox.Label"
                          data={{
                            link_to_terms_of_use: (
                              <ExternalLinkWithModal
                                href={`/${activeLanguage.code}/terms/user/`}
                                component={TermsUseContent}
                                isGolfhaftetRequest={isGolfhaftetRequest}
                              >
                                {translate('Register.form.termsCheckbox.terms_of_use_label')}
                              </ExternalLinkWithModal>
                            ),
                            link_to_privacy_policy: (
                              <ExternalLinkWithModal
                                href={`/${activeLanguage.code}/terms/privacy/`}
                                component={PrivacyPolicyContent}
                                isGolfhaftetRequest={isGolfhaftetRequest}
                              >
                                {translate('Register.form.termsCheckbox.privacy_policy_label')}
                              </ExternalLinkWithModal>
                            ),
                          }}
                        />
                      }
                      register={register}
                      rules={{ required: true }}
                      error={errors.terms && translate('Register.form.termsCheckbox.error')}
                    />
                  </Col>
                </Row>
                <div className="text-end">
                  {error && (
                    <div className="mb-1 text-danger">
                      {typeof error === 'string' ? error : <Translate id="Register.formError" />}
                    </div>
                  )}
                  <Button color="success" type="submit" disabled={disableButton}>
                    {disableButton ? (
                      <PulseLoader size={10} color="#fff" />
                    ) : (
                      <Translate id="default.Register" />
                    )}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default RegisterForm;
