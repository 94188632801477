export const THIRD_PARTY = 'Third-Party';
export const SUPER_ADMIN = 'Super admin';

export const PAYMENT_METHOD_CREDITCARD = 1;
export const PAYMENT_METHOD_INVOICE = 2;
export const PAYMENT_METHOD_VOUCHER = 3;

export const PAYMENT_METHODS = {
  1: PAYMENT_METHOD_CREDITCARD,
  2: PAYMENT_METHOD_INVOICE,
  3: PAYMENT_METHOD_VOUCHER
};

export const ORDER_STATUS_CART = 0;
export const ORDER_STATUS_AWAITING_PAYMENT = 1;
export const ORDER_STATUS_COMPLETED = 2;
export const ORDER_STATUS_EXPIRED = 4;
export const ORDER_STATUS_PAYMENT_EXPIRED = 6;

export const BOOKING_STATUS_CONFIRMED = 2;
export const BOOKING_STATUS_EXPIRED = 8;
export const BOOKING_STATUS_PAYMENT_EXPIRED = 11;
