import React, { FC, useMemo } from 'react';
import { Helmet as ReactHelmet, HelmetProps as ReactHelmetProps } from 'react-helmet';
import { TranslateOptions, TranslatePlaceholderData } from 'react-localize-redux';
import { useLocation } from 'react-router-dom';

import { TranslationKeys } from '../../TranlsationKeys';
import useTranslate from '../../hooks/useTranslate';

const onMissingTranslation: TranslateOptions['onMissingTranslation'] = ({
  defaultTranslation,
  translationId,
}) =>
  defaultTranslation !==
  "No default translation found! Ensure you've added translations for your default language."
    ? defaultTranslation
    : translationId;

interface HelmetProps {
  titleTranslationKey?: TranslationKeys;
  descriptionTranslationKey?: TranslationKeys;
  title?: string;
  description?: string;
  titleData?: TranslatePlaceholderData;
  descriptionData?: TranslatePlaceholderData;
}

const Helmet: FC<HelmetProps> = props => {
  const {
    titleTranslationKey,
    descriptionTranslationKey,
    title,
    description,
    titleData = {},
    descriptionData = {},
  } = props;
  const { translate, languages, activeLanguage } = useTranslate();
  const { pathname } = useLocation();

  const link = useMemo<ReactHelmetProps['link']>(
    () => [
      { rel: 'canonical', href: `${window.location.origin}${pathname}` },
      /**
       * @see
       * https://developers.google.com/search/docs/specialty/international/localized-versions#html
       */
      ...languages.map(language => ({
        rel: 'alternate',
        href: `${window.location.origin}${pathname.replace(
          new RegExp(`^/${activeLanguage.code}/`),
          `/${language.code}/`
        )}`,
        /**
         * @see
         * https://developers.google.com/search/docs/specialty/international/localized-versions#language-codes
         * https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
         */
        hrefLang: language.code === 'dk' ? 'da' : language.code,
      })),
      /**
       * @see
       * https://developers.google.com/search/docs/specialty/international/localized-versions?hl=ru#all-method-guidelines
       */
      {
        rel: 'alternate',
        href: `${window.location.origin}${pathname.replace(
          new RegExp(`^/${activeLanguage.code}/`),
          '/en/'
        )}`,
        hrefLang: 'x-default',
      },
    ],
    [activeLanguage.code, languages, pathname]
  );

  const metaTitle = useMemo(
    () =>
      titleTranslationKey
        ? `${translate(titleTranslationKey, titleData, { onMissingTranslation })} | OnTee.com`
        : title
        ? `${title}  | OnTee.com`
        : 'OnTee.com',
    [title, titleTranslationKey, titleData, translate]
  );
  const metaDescription = useMemo(
    () =>
      descriptionTranslationKey
        ? String(translate(descriptionTranslationKey, descriptionData, { onMissingTranslation }))
        : description
        ? description
        : 'OnTee',
    [description, descriptionData, descriptionTranslationKey, translate]
  );

  return (
    <ReactHelmet
      link={link}
      htmlAttributes={{ lang: activeLanguage.code === 'dk' ? 'da' : activeLanguage.code }}
    >
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />

      <meta property="og:description" content={metaDescription} />

      <meta property="twitter:title" content={metaTitle} />

      <meta property="twitter:description" content={metaDescription} />
    </ReactHelmet>
  );
};

export default Helmet;
