import { FC } from 'react';
import { Translate } from 'react-localize-redux';
import { Container } from 'reactstrap';

import sgfPartner from '../assets/img/SGF-official-partner.png';
import { titleStyles } from '../assets/styles';
import { LinkComponent } from '../components';
import Benefits from '../components/Benefits/Benefits';
import useBenefitsVisibility from '../hooks/useBenefitsVisibility';
import useTranslate from '../hooks/useTranslate';
import useCountries from '../services/apis/countries/api';
import useStore from '../store/useStore';

const FooterContainer: FC = () => {
  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);
  const { activeLanguage } = useTranslate();
  const countries = useCountries();
  const { showBenefitsOnMobile: showBenefits } = useBenefitsVisibility();

  if (isGolfhaftetRequest) return null;

  return (
    <>
      {showBenefits && (
        <Container className="my-4">
          <Benefits />
        </Container>
      )}
      <footer className="footer mt-auto">
        <Container className="py-5">
          <div className="d-flex flex-column flex-md-row text-center text-md-left justify-content-md-between">
            <div>
              <span style={titleStyles.footerLink}>
                <Translate id="FooterContainer.Menu.Ontee.Title" />
              </span>
              <ul className="mb-3">
                {/* <li>
                <LinkComponent to="news/">
                  <Translate id="FooterContainer.Menu.Ontee.Links.Press" />
                </LinkComponent>
              </li> */}
                {/* <li>
                  <LinkComponent
                    to="destinations/"
                    className="d-inline-flex gap-1 align-items-center"
                  >
                    <Translate id="FooterContainer.Menu.Ontee.Links.Destinations" />
                    <Badge color="success">New</Badge>
                  </LinkComponent>
                </li> */}
                <li>
                  <LinkComponent to="giftcard/" className="d-inline-flex gap-1 align-items-center">
                    <Translate id="FooterContainer.Menu.Ontee.Links.GiftCard" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="club19/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.Club19" />{' '}
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="help/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.Help" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="about/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.About" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="company/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.Contact" />
                  </LinkComponent>
                </li>
                {/* <li>
                <LinkComponent to="company/">
                  <Translate id="FooterContainer.Menu.Ontee.Links.CompanyInfo" />
                </LinkComponent>
              </li> */}
                <li>
                  <LinkComponent to="app/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.App" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="guaranteed/">
                    <Translate id="FooterContainer.Menu.Ontee.Links.Guaranteed" />
                  </LinkComponent>
                </li>
                {activeLanguage.code === 'de' && (
                  <li>
                    <LinkComponent to="impressum/">
                      <Translate id="FooterContainer.Menu.Ontee.Links.Imprint" />
                    </LinkComponent>
                  </li>
                )}
              </ul>
            </div>
            <div>
              <span style={titleStyles.footerLink}>
                <Translate id="FooterContainer.Menu.Discover.Title" />
              </span>
              <ul className="mb-3">
                {/* <li>
                <LinkComponent to="popular">
                  <Translate id="FooterContainer.Menu.Discover.Links.PopularDestinations" />
                </LinkComponent>
              </li>
               <li>
                  <LinkComponent to="invite/friend">
                    <Translate id="Links.invite.Title" /> <Translate id="Links.invite.friend.Title" />
                   </LinkComponent>
                 </li> */}
                <li>
                  <LinkComponent to="inspirations/">
                    <Translate id="FooterContainer.Menu.Discover.Links.Inspirations" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="invite/club/">
                    <Translate id="Links.invite.Title" /> <Translate id="Links.invite.club.Title" />
                  </LinkComponent>
                </li>

                <li>
                  <LinkComponent to="register/club/">
                    <Translate id="FooterContainer.Menu.Discover.Links.RegisterClub" />
                  </LinkComponent>
                </li>

                {/* <li>
                <LinkComponent to="guide/">
                  <Translate id="FooterContainer.Menu.Discover.Links.Guide" />
                </LinkComponent>
              </li> */}

                <li>
                  <LinkComponent to="courses/">
                    <Translate id="Links.courses.Title" />
                  </LinkComponent>
                </li>

                <li>
                  <LinkComponent to="courses/countries/">
                    <Translate id="Links.courses.country.Title" />
                  </LinkComponent>
                </li>

                <li>
                  <LinkComponent to="courses/districts/">
                    <Translate id="Links.courses.district.Title" />
                  </LinkComponent>
                </li>
              </ul>
            </div>
            <div>
              <span style={titleStyles.footerLink}>
                <Translate id="FooterContainer.Menu.GolfEurope.Title" />
              </span>
              <ul className="mb-3">
                {countries?.map(country => (
                  <li key={country.id}>
                    <LinkComponent to={`courses/countries/${country.name}/`}>
                      <Translate
                        id="FooterContainer.Menu.GolfEurope.Links.GolfIn"
                        data={{
                          country:
                            activeLanguage.code === 'de' && country.name === 'Slowakei'
                              ? `der ${country.name}`
                              : country.name,
                        }}
                      />
                    </LinkComponent>
                  </li>
                ))}
              </ul>
            </div>

            <div className="">
              <span style={titleStyles.footerLink}>
                <Translate id="FooterContainer.Menu.Terms.Title" />
              </span>
              <ul>
                <li>
                  <LinkComponent to="terms/payment/">
                    <Translate id="FooterContainer.Menu.Terms.Links.TermsOfPayment" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="terms/user/">
                    <Translate id="FooterContainer.Menu.Terms.Links.TermsOfUse" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="terms/privacy/">
                    <Translate id="FooterContainer.Menu.Terms.Links.PrivacyPolicy" />
                  </LinkComponent>
                </li>
                <li>
                  <LinkComponent to="terms/cookies/">
                    <Translate id="FooterContainer.Menu.Terms.Links.Cookies" />
                  </LinkComponent>
                </li>
              </ul>
            </div>
          </div>
          <img src={sgfPartner} height="150" alt="sgf official partner" />
        </Container>
      </footer>
    </>
  );
};

export default FooterContainer;
