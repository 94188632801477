import React, { useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';

import DEFlag from 'flag-icons/flags/4x3/de.svg';
import DKFlag from 'flag-icons/flags/4x3/dk.svg';
import ENFlag from 'flag-icons/flags/4x3/gb.svg';
import NOFlag from 'flag-icons/flags/4x3/no.svg';
import SEFlag from 'flag-icons/flags/4x3/se.svg';
import { orderBy, sortBy } from 'lodash-es';

import onTeeLogo from '../../assets/img/logotype.png';
import useBreakpoints from '../../hooks/useBreakpoints';
import useTranslate from '../../hooks/useTranslate';
import useCountries from '../../services/apis/countries/api';
import useDistricts from '../../services/apis/districts/api';
import queryClient from '../../services/reactQuery';
import useStore from '../../store/useStore';
import LangSelect from '../LangSelect';
import MultilevelMenu, { MenuItem } from '../MultilevelMenu/MultilevelMenu';
import LinkComponent from '../common/LinkComponent';

const flags = {
  sv: SEFlag,
  dk: DKFlag,
  no: NOFlag,
  en: ENFlag,
  de: DEFlag,
};

const countryOrderByCode = [
  'ES', // Spain
  'PT', // Portugal
  'IT', // Italy
  'FR', // France
  'DE', // Germany
  'BE', // Belgium
  'CZ', // Czech Republic
  'SK', // Slovakia
  'SE', // Sweden
];

function NavbarMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { activeLanguage } = useTranslate();

  const isLoggedIn = useStore(state => state.isLoggedIn);
  const logout = useStore(state => state.logout);

  const countries = useCountries();
  const districts = useDistricts();
  const { md: showDistricts } = useBreakpoints();

  const menuItems = useMemo<MenuItem[]>(
    () => [
      ...sortBy(countries, [
        country => {
          const index = countryOrderByCode.indexOf(country.code ?? '');

          return index === -1 ? countries.length : index;
        },
      ]).map(country => ({
        id: `country-${country.id}`,
        href: `/${activeLanguage.code}/courses/countries/${country.name}/`,
        label: country.name,
      })),
      ...(showDistricts
        ? orderBy(districts, ['name'], ['asc']).map(district => ({
            id: `district-${district.id}`,
            href: `/${activeLanguage.code}/courses/districts/${district.name}/`,
            label: district.name,
            parentId: `country-${district.countryId}`,
          }))
        : []),
    ],
    [countries, districts, activeLanguage, showDistricts]
  );

  const logoutUser = () => {
    logout();
    queryClient.clear();
  };
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Navbar
      container="md"
      light
      color="light"
      expand="md"
      style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
    >
      <NavbarBrand tag={LinkComponent} to="">
        <img src={onTeeLogo} height="40" alt="ontee logo" />
      </NavbarBrand>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <NavbarToggler onClick={toggle} />
      <Collapse key={String(isOpen)} navbar isOpen={isOpen} className="justify-content-end">
        <Nav navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <Translate id="HeaderContainer.Link.BookTeeTime" />
            </DropdownToggle>
            <DropdownMenu
              style={{
                left: '0 !important',
                right: 'auto !important',
                padding: 0,
                border: 0,
                minWidth: 'auto',
              }}
            >
              <MultilevelMenu items={menuItems} />
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem key="giftcard" className="d-flex d-md-none d-lg-flex" onClick={close}>
            <NavLink tag={LinkComponent} to="giftcard/">
              <Translate id="HeaderContainer.Link.GiftCard" />
            </NavLink>
          </NavItem>
          <NavItem key="app" className="d-flex d-md-none d-lg-flex" onClick={close}>
            <NavLink tag={LinkComponent} to="app/">
              <Translate id="HeaderContainer.Link.DownloadApp" />
            </NavLink>
          </NavItem>

          {isLoggedIn ? (
            <>
              <NavItem key="profile" onClick={close}>
                <NavLink tag={LinkComponent} to="profile/">
                  <Translate id="HeaderContainer.Link.profile" />
                </NavLink>
              </NavItem>

              <NavItem key="singout" onClick={close}>
                <NavLink href="#" onClick={logoutUser}>
                  <Translate id="HeaderContainer.Link.LogOut" />
                </NavLink>
              </NavItem>
            </>
          ) : (
            <>
              <NavItem key="signin" onClick={close}>
                <NavLink tag={LinkComponent} to="signin/">
                  <Translate id="HeaderContainer.Link.SignIn" />
                </NavLink>
              </NavItem>
            </>
          )}
          <LangSelect flags={flags} />
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default NavbarMenu;
