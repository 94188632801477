import { Context, useContext } from 'react';
import {
  // @ts-expect-error missing types (https://github.com/ryandrewjohnson/react-localize-redux/issues/152)
  LocalizeContext,
  TranslatePlaceholderData,
  type LocalizeContextProps,
  TranslateOptions,
  LocalizedElement
} from 'react-localize-redux';
import { TranslationKeys } from '../TranlsationKeys';

type TranslateFunction = (
  value: TranslationKeys,
  data?: TranslatePlaceholderData,
  options?: TranslateOptions
) => LocalizedElement;

function useTranslate() {
  return useContext(
    LocalizeContext as Context<
      Omit<LocalizeContextProps, 'translate'> & { translate: TranslateFunction }
    >
  );
}

export default useTranslate;
