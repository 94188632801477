import React, { useEffect, useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import Fuse from 'fuse.js';
import { throttle } from 'lodash-es';
import CloseIcon from 'material-react-icons/Close';
import SearchIcon from 'material-react-icons/Search';

import { calculateDistance } from '../helpers/distance';
import useTranslate from '../hooks/useTranslate';
// import { MappedCityType } from '../services/apis/cities/types';
// import { MappedCountryType } from '../services/apis/countries/types';
// import { MappedDistrictType } from '../services/apis/districts/types';
// import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import useSearchData from '../services/apis/search/useSearchData';
import { latinizeReplace } from '../services/language/latinize';
import useStore from '../store/useStore';

// interface SearchFieldProps {
//   value?: string;
//   onSearchSelect: (
//     results: MappedGolfCourseType | MappedCountryType | MappedDistrictType | MappedCityType | null
//   ) => void;
//   placeholder: string;
//   onFocus?: FocusEventHandler<HTMLInputElement>;
//   onClose?: () => void;
// }

const SEARCH_LIMIT = 7;
const SCORE_THRESHOLD = 0.1;
const SCORE_THRESHOLD_MAX = 0.5;
const DISTANCE_THRESHOLD = 100; // in kilometers
const NEARBY_GOLF_COURSES_LIMIT = 5;
const NEARBY_CITIES_LIMIT = 2;

const getCloseBySuggestions = (firstResult, golfCourses, cities, translate) => {
  const { district, country, latitude, longitude, name } = firstResult.item;
  const districtSuggestion = {
    ...district,
    countryId: country.id,
    type: 'district',
    typeLabel: translate('search.suggestion.type.district'),
  };
  const countrySuggestion = {
    ...country,
    type: 'country',
    typeLabel: translate('search.suggestion.type.country'),
  };
  const latLng = { latitude, longitude };
  const firstPartOfName = getFirstPartOfName(name);

  let golfCoursesNearby = filterNearbyGolfCourses(
    golfCourses,
    firstResult.item.id,
    firstPartOfName,
    latLng,
    translate
  );
  const citiesNearby = filterNearbyCities(cities, latLng, translate);

  return [...golfCoursesNearby, ...citiesNearby, districtSuggestion, countrySuggestion];
};

const getFirstPartOfName = name => {
  const nameParts = name.split(' ');
  if (nameParts[0].length >= 3) {
    return nameParts[0].toLowerCase();
  }
  return nameParts.slice(0, 2).join(' ').toLowerCase();
};

const filterNearbyGolfCourses = (golfCourses, excludeId, namePart, latLng, translate) => {
  let nearbyGolfCourses = golfCourses
    .filter(g => g.id !== excludeId && g.search.includes(namePart))
    .slice(0, NEARBY_GOLF_COURSES_LIMIT)
    .map(g => ({
      ...g,
      type: 'golfCourse',
      typeLabel: translate('search.suggestion.type.golfCourse'),
    }));

  if (nearbyGolfCourses.length === 0) {
    nearbyGolfCourses = golfCourses
      .filter(
        g =>
          g.id !== excludeId &&
          calculateDistance({ latitude: g.latitude, longitude: g.longitude }, latLng) <
            DISTANCE_THRESHOLD
      )
      .slice(0, NEARBY_GOLF_COURSES_LIMIT)
      .map(g => ({
        ...g,
        type: 'golfCourse',
        typeLabel: translate('search.suggestion.type.golfCourse'),
      }));
  }

  return nearbyGolfCourses;
};

const filterNearbyCities = (cities, latLng, translate) => {
  return cities
    .filter(
      c =>
        calculateDistance({ latitude: c.latitude, longitude: c.longitude }, latLng) <
        DISTANCE_THRESHOLD
    )
    .slice(0, NEARBY_CITIES_LIMIT)
    .map(c => ({
      ...c,
      type: 'city',
      typeLabel: translate('search.suggestion.type.city'),
    }));
};

const fuse = new Fuse([], { keys: ['search'], includeScore: true });

const SearchField = props => {
  const { value: courseName, onSearchSelect, placeholder, onFocus, onClose } = props;
  const [value, setValue] = useState(courseName || '');
  const { golfCourses = [], countries = [], districts = [], cities = [] } = useSearchData();
  const { translate } = useTranslate();
  const collection = useMemo(
    () => [
      ...golfCourses.map(item => ({
        ...item,
        type: 'golfCourse',
        typeLabel: translate(`search.suggestion.type.golfCourse`),
      })),
      ...countries.map(item => ({
        ...item,
        type: 'country',
        typeLabel: translate(`search.suggestion.type.country`),
      })),
      ...districts.map(item => ({
        ...item,
        type: 'district',
        typeLabel: translate(`search.suggestion.type.district`),
      })),
      ...cities.map(item => ({
        ...item,
        type: 'city',
        typeLabel: translate(`search.suggestion.type.city`),
      })),
    ],
    [golfCourses, countries, districts, cities, translate]
  );
  const [suggestions, setSuggestions] = useState([]);

  const setSearchPhrase = useStore(state => state.setSearchPhrase);

  useEffect(() => {
    setValue(courseName || '');
  }, [courseName]);

  useEffect(() => {
    fuse.setCollection(collection);
  }, [collection]);

  const saveSearchPhrase = useMemo(
    () =>
      throttle(
        searchValue => {
          if (searchValue.length >= 4) {
            setSearchPhrase(searchValue);
          }
        },
        2000,
        { leading: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Autosuggest
      containerProps={{
        className: 'position-relative',
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={data => {
        const searchValue = data.value?.trim().toLowerCase();
        if (searchValue.length < 2) return;

        saveSearchPhrase(searchValue);

        const pattern = searchValue.replace(/[^A-Za-z\d[\] ]/g, latinizeReplace);
        const results = fuse.search(pattern, { limit: SEARCH_LIMIT });
        const firstResult = results[0] || null;

        if (
          firstResult?.score &&
          firstResult.score < SCORE_THRESHOLD &&
          firstResult.item.type === 'golfCourse'
        ) {
          const suggest = getCloseBySuggestions(firstResult, golfCourses, cities, translate);
          setSuggestions([firstResult.item, ...suggest]);
          return;
        }

        const newSuggestions = results
          .filter(result => result.score < SCORE_THRESHOLD_MAX)
          .map(result => result.item);

        setSuggestions(newSuggestions);
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      onSuggestionSelected={(_, { suggestion }) => {
        onSearchSelect(suggestion);
      }}
      getSuggestionValue={suggestion => suggestion.name}
      renderSuggestion={suggestion => (
        <div className="d-flex gap-2 justify-content-between align-items-center">
          <span>{suggestion.name}</span>
          <small className="text-muted flex-shrink-0" style={{ fontWeight: 'normal' }}>
            {suggestion.typeLabel}
          </small>
        </div>
      )}
      renderInputComponent={inputProps => (
        <div className="form-control d-flex align-content-center align-items-center">
          <SearchIcon />
          <input {...inputProps} autoComplete="off" autoCorrect="off" spellCheck={false} />
          <CloseIcon
            onClick={() => {
              setValue('');
              onSearchSelect(null);
              onClose?.();
            }}
          />
        </div>
      )}
      inputProps={{
        className: 'flex-fill',
        placeholder,
        value,
        onChange: (_, params) => {
          setValue(params.newValue);
        },
        onFocus,
      }}
    />
  );
};

export default SearchField;
