import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';
import styles from './benefits.module.scss';
import imageCancellationEn from './images/cancellation_en.png';
import imageCancellationSv from './images/cancellation_sv.png';
import imagePayLaterEn from './images/pay_later_en.png';
import imagePayLaterSv from './images/pay_later_sv.png';
import imagePriceGuaranteeEn from './images/price_guarantee_en.png';
import imagePriceGuaranteeSv from './images/price_guarantee_sv.png';

const Benefits: FC = () => {
  const { translate, activeLanguage } = useTranslate();
  const languageCode = activeLanguage.code;

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-start justify-content-sm-between align-items-sm-start gap-2">
      {[
        {
          img: languageCode === 'sv' ? imageCancellationSv : imageCancellationEn,
          alt: 'cancellation',
          text: translate('benefits.cancellation'),
        },
        {
          url: `/${languageCode}/guaranteed/`,
          img: languageCode === 'sv' ? imagePriceGuaranteeSv : imagePriceGuaranteeEn,
          alt: 'price',
          text: translate('benefits.priceGuarantee.text'),
          tooltip: translate('benefits.priceGuarantee.tooltip'),
        },
        {
          img: languageCode === 'sv' ? imagePayLaterSv : imagePayLaterEn,
          alt: 'book',
          text: translate('benefits.payLater'),
        },
      ].map(({ img, alt, text, url = '', tooltip = '' }, index) => (
        <div
          key={`item-${index + 1}`}
          className="d-flex align-items-center gap-4 flex-column flex-sm-row w-100"
        >
          {url ? (
            <Link to={url}>
              <img src={img} alt={alt} width={100} height={100} className="flex-shrink-0" />
            </Link>
          ) : (
            <img src={img} alt={alt} width={100} height={100} className="flex-shrink-0" />
          )}
          <div className={classNames('text-center text-sm-start', tooltip && styles.tooltip)}>
            {text}
            {tooltip && (
              <div className="tooltip bs-tooltip-bottom fade">
                <div className="arrow tooltip-arrow" style={{ left: 'calc(50% - 6px)' }} />
                <div className="tooltip-inner">{tooltip}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
