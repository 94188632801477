import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { emailRegex } from '../helpers/regex';
import useTranslate from '../hooks/useTranslate';
import useLogin from '../services/apis/auth/login';
import { LoginInputType } from '../services/apis/auth/login.types';
import useStore from '../store/useStore';
import Input from './form/Input';

interface LoginFormProps {
  showRegister: () => void;
  showGuestForm: () => void;
  showForgotPassword: () => void | null;
}

const LoginForm: FC<LoginFormProps> = props => {
  const { showRegister, showGuestForm, showForgotPassword } = props;
  const { translate, activeLanguage } = useTranslate();

  const openAuthModal = useStore(state => state.openAuthModal);
  const authModalSource = useStore(state => state.authModalSource);
  const authModalEmail = useStore(state => state.authModalEmail);
  const authModalGmCardNr = useStore(state => state.authModalGmCardNr);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputType>({
    defaultValues: {
      username: authModalEmail || '',
      password: '',
      ghCardNr: authModalGmCardNr ?? '',
      ghEmail: authModalEmail ?? '',
    },
  });
  const navigate = useNavigate();
  const { mutate: login, isLoading, error } = useLogin();

  const redirect = () => {
    document.body.classList.remove('modal-open');
    openAuthModal(false);
    navigate(`/${activeLanguage.code}/signin/forgot/`);
  };

  const isGolfamoreFlow = authModalSource === 'gmBookingFlow';

  return (
    <Card>
      <CardHeader className="pt-5 px-3 px-sm-5">
        <h1>
          <Translate id="SignInContainerTitle" />
        </h1>
      </CardHeader>
      <CardBody className="px-3 pt-0 px-sm-5">
        {authModalSource !== 'gmBookingFlow' && (
          <div className="d-flex align-center">
            <Translate id="SignInContainerCreateAccount.first" />
            <Button
              color="link"
              style={{ paddingRight: '5px', paddingLeft: '5px', paddingTop: 0, paddingBottom: 0 }}
              onClick={showRegister}
            >
              <Translate id="SignInContainerCreateAccount.middle" />
            </Button>
            <Translate id="SignInContainerCreateAccount.last" />
          </div>
        )}

        <form onSubmit={handleSubmit(data => login(data))}>
          {isGolfamoreFlow && (
            <div className="mt-3 alert alert-warning">
              <h5>{translate('SignInContainerCreateAccount.GolfhäftetCardInfo.title')}</h5>
              <p>
                {translate('SignInContainerCreateAccount.GolfhäftetCardInfo.text', {
                  cardNr: authModalGmCardNr,
                })}
              </p>
              {authModalGmCardNr && <input type="hidden" {...register('ghCardNr')} />}
            </div>
          )}

          <Row className="mt-4">
            <Col>
              <Input
                name="username"
                label={String(translate('default.Email'))}
                autoComplete="email"
                register={register}
                rules={{ required: true, pattern: emailRegex }}
                // disabled={formDisabled}
                error={errors.username && translate('errorInvalidEmail')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                name="password"
                type="password"
                label={String(translate('default.Password'))}
                autoComplete="off"
                register={register}
                rules={{ required: true }}
                // disabled={formDisabled}
                error={errors.password && ''}
              />
            </Col>
          </Row>

          <div className="text-end">
            <>
              {error && <div className="mb-1 text-danger">{translate('errorFailedToSignIn')}</div>}
              {authModalSource === 'giftCardForm' && (
                <Button
                  color="success"
                  className="me-3"
                  disabled={isLoading}
                  onClick={showGuestForm}
                >
                  {translate('guestForm.title')}
                </Button>
              )}
              <Button color="success" type="submit" disabled={isLoading}>
                {isLoading ? <PulseLoader size={10} color="#fff" /> : translate('default.SignIn')}
              </Button>
            </>
          </div>
          <div className="text-end mt-1">
            <Button
              color="link"
              className="text-success btn-no-padding"
              onClick={() => (showForgotPassword ? showForgotPassword() : redirect())}
            >
              <Translate id="SignInContainerForgotPassword" />
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default LoginForm;
