import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import useBenefitsVisibility from '../../hooks/useBenefitsVisibility';
import useStore from '../../store/useStore';
import Benefits from '../Benefits/Benefits';
import NavbarMenu from '../NavbarMenu/NavbarMenu';
import SearchBar from '../SearchBar/SearchBar';
import StickyHeader from '../StickyHeader/StickyHeader';
import { nonBookingGolfClubSegments } from './config';

function Navbar() {
  const location = useLocation();
  const { pathname } = location;
  const { showBenefitsOnDesktop: showBenefits } = useBenefitsVisibility();

  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);

  const { showSearchBar } = useMemo(() => {
    const matchBookingPages = matchPath('/:locale/booking/:clubId/:courseId', pathname);
    const matchCoursesPages = matchPath('/:locale/courses/*', pathname);
    const matchCampingPages = matchPath('/:locale/camping/*', pathname);
    const ignoreSegment =
      matchBookingPages && nonBookingGolfClubSegments.includes(matchBookingPages.params.clubId);

    return {
      showSearchBar:
        (matchBookingPages && !ignoreSegment) || !!matchCoursesPages || !!matchCampingPages,
    };
  }, [pathname]);

  return (
    <>
      <StickyHeader>
        {!isGolfhaftetRequest && <NavbarMenu />}
        {showSearchBar && <SearchBar />}
      </StickyHeader>
      {showBenefits && (
        <Container className="my-4">
          <Benefits />
        </Container>
      )}
    </>
  );
}

export default Navbar;
