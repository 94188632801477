import React, { FC, MutableRefObject, PropsWithChildren, useRef, useState } from 'react';

import { HidingHeader, useHidingHeader } from 'hiding-header-react';

import classNames from '../../helpers/classNames';
import styles from './StickyHeader.module.scss';

const HidingContext: FC<
  PropsWithChildren<{ instanceRef: MutableRefObject<ReturnType<typeof useHidingHeader>> }>
> = props => {
  const { children, instanceRef } = props;
  instanceRef.current = useHidingHeader();

  return <>{children}</>;
};

const StickyHeader: FC<PropsWithChildren> = props => {
  const { children } = props;
  const [isHome, setIsHome] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const hidingHeader = useRef<ReturnType<typeof useHidingHeader>>();

  return (
    <HidingHeader
      snap={false}
      className={classNames(styles.container, !isHome && isVisible && styles.shadow)}
      innerClassName={styles.inner}
      onHomeChange={setIsHome}
      onVisibleHeightChange={height => setIsVisible(height > 0)}
      onHeightChange={() => {
        hidingHeader?.current?.reveal();
      }}
    >
      <HidingContext instanceRef={hidingHeader}>{children}</HidingContext>
    </HidingHeader>
  );
};

export default StickyHeader;
