import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';

import { intersection, shuffle } from 'lodash-es';

import Benefits from '../../components/Benefits/Benefits';
import DownloadApp from '../../components/DownloadApp/DownloadApp';
import GolfCamping from '../../components/GolfCamping/GolfCamping';
import GolfCoursesCards from '../../components/GolfCoursesCards/GolfCoursesCards';
import { Header } from '../../components/HomePageContent/Header';
import HomePageContentGolfCourses from '../../components/HomePageContent/HomePageContentGolfCourses';
import { Section } from '../../components/HomePageContent/Section';
import SearchBar from '../../components/SearchBar/SearchBar';
import { SelectedOptionType } from '../../components/SearchBarSwitcher/SearchBarSwitcher';
import UncontrolledSlider from '../../components/UncontrolledSlider/UncontrolledSlider';
import Helmet from '../../components/common/Helmet';
import useHomePageSlides from '../../hooks/useHomePageSlides';
import useTranslate from '../../hooks/useTranslate';
import { useFavouriteCourses } from '../../services/apis/favouriteCourses/api';
import useGolfCourses from '../../services/apis/golfCourses/api';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import useStatistics from '../../services/apis/statistics';
import useStore from '../../store/useStore';
import styles from './HomePage.module.scss';

const MAX_SLIDER_ITEMS = 7;

const STATISTICS_FILTER = {
  pt: { country: 'pt' },
  es: { country: 'es' },
  it: { country: 'it' },
};

const HomePage = () => {
  const { activeLanguage, translate } = useTranslate();
  const { data: golfCourses } = useGolfCourses();
  const navigate = useNavigate();

  const isAccommodationView = useStore(state => state.withAccommodation);
  const homePageSlides = useHomePageSlides();

  const allIds = useMemo(() => golfCourses?.map(({ golfCourse: { id } }) => id), [golfCourses]);

  const { data: portugalStatisticsData } = useStatistics(STATISTICS_FILTER['pt']);
  const { data: italyStatisticsData } = useStatistics(STATISTICS_FILTER['it']);
  const { data: spainStatisticsData } = useStatistics(STATISTICS_FILTER['es']);

  const favouriteGolfCoursesQuery = useFavouriteCourses();

  const favouriteGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const favouriteIds =
      favouriteGolfCoursesQuery.data?.map(({ GolfCourseId }) => GolfCourseId) ?? [];
    const ids = intersection(favouriteIds, allIds);

    const extraGolfCourseIds = (
      [spainStatisticsData, portugalStatisticsData, italyStatisticsData].flatMap(
        item => item?.mostBookedGolfclubsLastMonth?.slice(0, 5) || []
      ) as Array<{ id: number }>
    ).map(({ id }) => golfCourses.find(item => item.golfClub.id === id)?.golfCourse.id!);

    ids.push(...extraGolfCourseIds);

    // return favourites courses all + extra courses from popular
    return [...ids].map(id => golfCourses.find(item => item.golfCourse.id === id)!);
  }, [
    golfCourses,
    favouriteGolfCoursesQuery.data,
    allIds,
    spainStatisticsData,
    portugalStatisticsData,
    italyStatisticsData,
  ]);

  const campingGolfCourses = useMemo(
    () =>
      shuffle(golfCourses?.filter(course => !!getGolfClubInfo(course)?.accommodationId)).slice(
        0,
        MAX_SLIDER_ITEMS
      ),
    [golfCourses]
  );

  const handleFilterChange = useCallback(
    (value: SelectedOptionType) => {
      if (value === 'accommodation') {
        navigate(`/${activeLanguage.code}/book-accommodation/`);
      }
    },
    [activeLanguage.code, navigate]
  );

  return (
    <>
      <Helmet
        titleTranslationKey="HomeContainerTitle"
        descriptionTranslationKey="HomeContainerhelmetDescription"
      />

      {/* Slider and Search*/}
      <UncontrolledSlider slides={homePageSlides}>
        <SearchBar layout="main" isNewDesign onFilterChange={handleFilterChange} />
      </UncontrolledSlider>

      {/*Benefits*/}
      <Section>
        <Container>
          <Header>{translate('benefits.title')}</Header>
          <Benefits />
        </Container>
      </Section>

      {/* Favourites */}
      {!!favouriteGolfCourses?.length && (
        <div className={styles.backgroundImageWrapper}>
          <Container>
            <Header>
              {translate('index.favourites.title', {
                value: translate('index.favourites.golf'),
              })}
            </Header>
            <GolfCoursesCards
              url={`/${activeLanguage.code}/profile/favorites/`}
              golfCourses={favouriteGolfCourses}
            />
          </Container>
        </div>
      )}

      <HomePageContentGolfCourses />

      <DownloadApp />

      {!!campingGolfCourses?.length && !isAccommodationView && (
        <GolfCamping courses={campingGolfCourses} />
      )}
    </>
  );
};

export default HomePage;
