import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { UncontrolledSliderSlideType } from '../components/UncontrolledSlider/UncontrolledSlider';
import useGolfCourses from '../services/apis/golfCourses/api';
import getGolfCourseUrl from '../services/apis/golfCourses/getGolfCourseUrl';
import useStore from '../store/useStore';
import useTranslate from './useTranslate';

const useHomePageSlides = (): UncontrolledSliderSlideType[] => {
  const isAccommodationView = useStore(state => state.withAccommodation);
  const { data: golfCourses } = useGolfCourses();
  const { activeLanguage, translate } = useTranslate();

  const getLinkToCourse = useCallback(
    (id: number) =>
      `/${activeLanguage.code}/booking/${getGolfCourseUrl(
        golfCourses?.find(item => item.golfCourse.id === id)
      )}`,
    [golfCourses, activeLanguage.code]
  );

  return useMemo(() => {
    if (isAccommodationView) {
      return [
        /**
         * #1
         */

        {
          title: (
            <>
              <div>{translate('heroBanner.accommodation.title')}</div>
            </>
          ),
          description: '',
          image: require('../pages/home/images/accommodation-bg-1920x1080.jpg'),
          placeholder: `data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAW1A+TWFkXGhqiHp1eHpxcYeHgpOVqqelcYWEdY+ThaGkpLS1`,
        },
      ];
    }

    return [
      /**
       * #1
       * !!! This is production ID !!!
       * golfCourseId: 1745
       * @description Real Club Valderrama - 18-hole course
       * @link https://www.ontee.com/en/booking/Real%20Club%20Valderrama/18-hole%20course
       */

      {
        title: (
          <>
            <div>{translate('heroBanner.teetimes.title')}</div>
            <Link className="fw-bold" to={getLinkToCourse(1745)}>
              Valderrama
            </Link>
          </>
        ),
        description: translate('heroBanner.teetimes.subtitle'),
        image: require('../pages/home/images/Valderrama_1920x1080.jpg'),
        placeholder: `data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAW1A+TWFkXGhqiHp1eHpxcYeHgpOVqqelcYWEdY+ThaGkpLS1`,
      },

      /**
       * #2
       * !!! This is production ID !!!
       * golfCourseId: 1032
       * @description Bro Hof Slott GC - Stadium Course
       * @link https://www.ontee.com/sv/booking/Bro%20Hof%20Slott%20GC/Bro%20Hof%20Slott%20GC%20-%20Stadium%20Course
       */

      {
        title: (
          <>
            <div>{translate('heroBanner.teetimes.title')}</div>
            <Link className="fw-bold" to={getLinkToCourse(1032)}>
              Bro Hof
            </Link>
          </>
        ),
        description: translate('heroBanner.teetimes.subtitle'),
        image: require('../pages/home/images/BroHof_1920x1080.jpg'),
        placeholder:
          'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
      },

      /**
       * #3
       * !!! This is production ID !!!
       * golfCourseId: 1601
       * @description Le National
       * @link https://www.ontee.com/en/booking/Le%20Golf%20National/Albatros%20-%201er%20Tee%201
       */

      {
        title: (
          <>
            <div>{translate('heroBanner.teetimes.title')}</div>
            <Link
              className="fw-bold"
              to={`booking/${getGolfCourseUrl(
                golfCourses?.find(item => item.golfCourse.id === 1601)
              )}`}
            >
              Le National
            </Link>
          </>
        ),
        description: translate('heroBanner.teetimes.subtitle'),
        image: require('../pages/home/images/Le_National_1920x1080.jpg'),
        // placeholder is generated with https://unpic-placeholder.netlify.app/
        placeholder:
          'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
      },
      /**
       * #4
       * !!! This is production ID !!!
       * golfCourseId: 1775
       * @description Marco Simone
       * @link https://www.ontee.com/en/booking/Marco%20Simone%20Golf%20&%20Country%20Club/Ryder%20Cup%20Course
       */
      {
        title: (
          <>
            <div>{translate('heroBanner.teetimes.title')}</div>
            <Link
              className="fw-bold"
              to={`booking/${getGolfCourseUrl(
                golfCourses?.find(item => item.golfCourse.id === 1775)
              )}`}
            >
              Marco Simone
            </Link>
          </>
        ),
        description: translate('heroBanner.teetimes.subtitle'),
        image: require('../pages/home/images/Marco_Simone_golf_course_1920x1080.jpg'),
        // placeholder is generated with https://unpic-placeholder.netlify.app/
        placeholder:
          'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
      },

      /**
       * #5
       * !!! This is production ID !!!
       * golfCourseId: 1744
       * @description Finca Cortesin Golf Club
       * @link https://www.ontee.com/en/booking/Finca%20Cortesin%20Golf%20Club/18%20hole%20course
       */

      {
        title: (
          <>
            <div>{translate('heroBanner.teetimes.title')}</div>
            <Link
              className="fw-bold"
              to={`booking/${getGolfCourseUrl(
                golfCourses?.find(item => item.golfCourse.id === 1744)
              )}`}
            >
              Finca Cortesin
            </Link>
          </>
        ),
        description: translate('heroBanner.teetimes.subtitle'),
        image: require('../pages/home/images/Finca_Cortesin_Golf_Club_1920x1080.jpg'),
        // placeholder is generated with https://unpic-placeholder.netlify.app/
        placeholder:
          'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
      },
    ];
  }, [getLinkToCourse, golfCourses, isAccommodationView, translate]);
};

export default useHomePageSlides;
